import React from 'react';
import './App.css';
import WebApp from './Elements/WebApp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


function App() {
  return (
    <div>
      <WebApp/>
      <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 9999 ,
      backgroundColor: 'transparent !important',
      borderRadius: '50%',
    }}>
          <a href="https://api.whatsapp.com/send?phone=+436769212103" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faWhatsapp} size="3x" color="#25D366" />
          </a>
        </div>
    </div>
  );
}

export default App;
