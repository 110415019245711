import * as React from 'react';
import ChildTab from './ChildTab';

export interface ITabsParentProps {
  tabActive:number;
}

export interface ITabsParentState {
    activeTab:number
}

export default class TabsParent extends React.Component<ITabsParentProps, ITabsParentState> {
  constructor(props: ITabsParentProps) {
    super(props);

    this.state = {
        activeTab:1
    }
  }
  steps = [
    {
      id: 1,
      name: 'Art der Dachfläche',
      status: 'active',
    },

    {
      id:2,
      name: 'Solarenergiespeicher',
      status: 'inactive',
    },
    {
      id: 3,
      name: 'Eindeckung',
      status: 'inactive',
    },
    {
      id: 4,
      name: 'Bemerkung/Zusätzliche Informationen',
      status: 'inactive',
    },
    {
      id: 5,
      name: 'Adresse and info',
      status: 'inactive',
    },
  ];

  
componentDidUpdate(prevProps: Readonly<ITabsParentProps>, prevState: Readonly<ITabsParentState>, snapshot?: any): void {
    if(prevProps.tabActive !== this.props.tabActive){
        this.setState({activeTab:this.props.tabActive})
    }
   
}
 

  public render() {
    return (
        <div className="flex sm:flex-row flex-col
        


         ">
        <div className="lg:w-2/6 w-4/5 mx-auto lg:ml-32 py-4 mt-6 ">
          {/* Vertical Tabs */}
          <ol className="space-y-4">
              {this.steps.map((step, index:number) => (
                <li key={step.id}>
                  <div
                    className={`w-full p-4 ${
                      step.id === this.state.activeTab
                        ? 'text-blue-700 bg-blue-100 border border-blue-300 rounded-lg dark:bg-gray-800 dark:border-blue-800 dark:text-blue-400'
                        : 'text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:border-green-800 dark:text-green-400'
                    }  `}
                    role="alert"
                  >
                    <div className="flex items-center justify-between">
                      <span className="sr-only">{step.name}</span>
                      <h3 className="font-medium">{index + 1}- {step.name}</h3>
                      {step.id === this.state.activeTab ? (
                        <svg
                          className="w-4 h-4"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 16 12"
                        >
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                        </svg>
                      ) : (
                        <svg
                          className="rtl:rotate-180 w-4 h-4"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 10"
                        >
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                        </svg>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ol>
        </div>
        <div className="lg:w-4/6 w-full sm:flex-none">
          {/* Content */}
          <ChildTab activeTab={this.props.tabActive} />
        </div>
      </div>
    );
  }
}
