import * as React from 'react';

export interface IWorkstepsProps {
}

export interface IWorkstepsState {
}

export default class Worksteps extends React.Component<IWorkstepsProps, IWorkstepsState> {
  constructor(props: IWorkstepsProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>
           <section>
        <div className="container px-6 m-auto items-center ">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className='mt-[12%] mb-auto' src="../../../img/Worksteps/w1.png" alt="" />
            </div>
            <div className="col-span-4 lg:col-span-6  lg:h-72 bg-[#D0E0F0] rounded-lg my-auto">
                <h1 className='text-4xl font-bold text-center mt-6'>Unsere Arbeitsschritte</h1>
                <p className='text-center text-[#002366] lg:mt-10 py-6 lg:py-1'>Machen Sie den Wechsel zu einem leuchtenden Zuhause mit RNZ-Photovoltaik! Besitzen Sie Ihre Zukunft und sparen Sie monatlich bei Ihren Stromrechnungen. Unsere hochwertigen Solarpanel-Lösungen bieten Ihnen nicht nur finanzielle Freiheit, sondern ermöglichen es Ihnen auch, aktiv zum Umweltschutz beizutragen. Verabschieden Sie sich von hohen Stromkosten und dunklen Räumen. Mit RNZ-Photovoltaik ist Ihr Zuhause immer hell und energieeffizient. Entscheiden Sie sich heute für eine nachhaltige Zukunft!</p>
            </div>
            <div className="col-span-4 lg:col-span-3  h-96">
            <img className='mt-[12%] mb-auto' src="../../../img/Worksteps/w2.png" alt="" />

            </div>

          </div>
        </div>
        <div>
            <h1 className='text-4xl font-bold text-center mt-3'>Schritte zur Installation von Solarmodulen</h1>
            
        </div>
      </section>
         <section className='mt-6'>
        <div className="container px-6 m-auto">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className=' mb-auto' src="../../../img/Worksteps/work1.png" alt="" />
                <p>
                <span className='text-[#002366] font-bold'>1. </span>
                <span className='text-[#002366]'>Erstberatung</span>
                </p>
            </div>
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className=' mb-auto' src="../../../img/Worksteps/work2.png" alt="" />
                <p>
                <span className='text-[#002366] font-bold'>2. </span>
                <span className='text-[#002366]'>Messung</span>
                </p>
            </div>
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className=' mb-auto' src="../../../img/Worksteps/work3.png" alt="" />
                <p>
                <span className='text-[#002366] font-bold'>3. </span>
                <span className='text-[#002366]'>Installation</span>
                </p>
            </div>
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className=' mb-auto' src="../../../img/Worksteps/work4.png" alt="" />
                <p>
                <span className='text-[#002366] font-bold'>4. </span>
                <span className='text-[#002366]'>Fertigstellung</span>
                </p>
            </div>
          </div>
        </div>
      </section>
      </div>
    );
  }
}
