import * as React from "react";
import Banner from "../Section/Banner";
import TabsParent from "../Layaout/TabsParent";
import store from "../../Store";

export interface IServicesState {
  activeTab: number;
}

export default class Services extends React.Component<{}, IServicesState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      activeTab: 1,
    };
  }


  componentDidMount() {
    store.subscribe(this.handleStoreChange);
  }

  componentWillUnmount() {
    store.unsubscribe(this.handleStoreChange);
  }

  handleStoreChange = () => {
    this.setState({ activeTab: store.getTabActive() });
  };
  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<IServicesState>, snapshot?: any): void {
    if (store.getTabActive() !== this.state.activeTab) {
     this.setState({activeTab:store.getTabActive()})
    }
      

  }

  public render() {
    return (
      <div className="pb-20">
        <Banner isHome={false} pageName="Services" color="white" />
        <div className="container">
          <TabsParent tabActive={this.state.activeTab} />
        </div>
      </div>
    );
  }
}
