import * as React from "react";
import CardImage from "../Section/CardImage";
import store from "../../Store";
import EventEmitter from 'events'; // or a similar event handling library
import ScrollToTop from "../Section/ScrollToTop";


export interface IChildTabProps {
  activeTab: number;
}

export interface IChildTabState {
  activeTab: number;
  dachRoof:string;
  brick:string;
  storage:string;
  carCharger:string;
  note:string;
  name:string;
  lastName:string;
  phone:string;
  email:string;
  street:string;
  houseNumber:string;
  zip:string;
  city:string;
  länge:string;
  breite:string;
  Leistung : string;
  Jahresverbrauch : string;
  Netzbetreiber : string;
  succesMsg:boolean;
  checkedBreiteLänge:boolean;
  conditionFuseBox:string;
  photo1: File | null;
  photo2: File | null;
  imagePath: string | null; // Store the base64 string or null if not available
  imagePath2: string | null; // Store the base64 string or null if not available


}
const tabEventEmitter = new EventEmitter(); // Example event emitter

export default class ChildTab extends React.Component<
  IChildTabProps,
  IChildTabState
> {
  constructor(props: IChildTabProps) {
    super(props);

    this.state = {
      activeTab: 1,
      dachRoof:'',
      brick:'',
      storage:'',
      carCharger:'',
      note:'',
      name:'',
      lastName:'',
      phone:'',
      email:'',
      street:'',
      houseNumber:'',
      zip:'',
      city:'',
      länge:'',
      breite:'',
      Leistung : '',
      Jahresverbrauch : '',
      Netzbetreiber : '',
      succesMsg:false,
      checkedBreiteLänge:false,
      conditionFuseBox:"",
      photo1: null,
      photo2: null,
      imagePath: '',
      imagePath2: '',

    };
<ScrollToTop />
  }
  artDerDachflacheRoof= [
    {
      id: 1,
      name: 'Satteldach',
      img: '../../../img/Roof/dutch-gable-roof.jpg',
    },
    {
      id: 2,
      name: 'Flachdach',
      img: '../../../img/Roof/flat-roof.jpg',
    },
    {
      id: 3,
      name: 'Pultdach',
      img: '../../../img/Roof/Pent.jpg',
    },
    {
      id: 4,
      name: 'Ich habe keine Ahnung',
      img: '../../../img/Roof/AllRoof.png',
    },
    
  ];

  
  brickRoof= [
    {
      id: 1,
      name: 'Blech',
      img: '../../../img/brick/blech.png',
    },
    {
      id: 2,
      name: 'Welleternit',
      img: '../../../img/brick/welleternit.png',
    },
    {
      id: 3,
      name: 'Ziegel',
      img: '../../../img/brick/ziegel2.png',
    },
    {
      id: 4,
      name: 'Ich habe keine Ahnung',
      img: '../../../img/brick/AllBrick.png',
    },
  ];
  storageArr= [
    {
      id: 1,
      name: 'ON',
      img: '../../../img/Electricity storage/on.png',
    },
    {
      id: 2,
      name: 'OFF',
      img: '../../../img/Electricity storage/off.png',
    },
    {
      id: 3,
      name: 'Ich habe keine Ahnung',
      img: '../../../img/Electricity storage/doNotKnow.jpg',
    },
  ];
  carChargerArr= [
    {
      id: 1,
      name: 'ON',
      img: '../../../img/Ecar/carWithE.png',
    },
    {
      id: 2,
      name: 'OFF',
      img: '../../../img/Ecar/carWithoutE.png',
    },
    {
      id: 3,
      name: 'Ich habe keine Ahnung',
      img: '../../../img/Electricity storage/doNotKnow.jpg',
    },
  ];
  componentDidMount() {
    tabEventEmitter.on('tabChange', this.handleTabChange);
  }

  componentWillUnmount() {
    tabEventEmitter.removeListener('tabChange', this.handleTabChange);
  }

  handleTabChange = (tab:number) => {
    store.setTabActive(tab);
    this.setState({ activeTab: store.getTabActive() });
  }

private handleSendEmail () : void {
    const {dachRoof,brick,storage,carCharger,note,name,lastName,phone,email,street,houseNumber,zip,city,
      länge,
      breite,
      Leistung,
      Jahresverbrauch,
      Netzbetreiber,
      checkedBreiteLänge,
     imagePath,
     imagePath2,
    } = this.state;
    const data = {
      dachRoof,
      brick,
      storage,
      carCharger,
      note,
      name,
      lastName,
      phone,
      email,
      street,
      houseNumber,
      zip,
      city,
      länge,
      breite,
      Leistung,
      Jahresverbrauch,
      Netzbetreiber,
      checkedBreiteLänge,
      imagePath,
      imagePath2,

    }
    console.log(data);
    fetch('https://www.rnz-photovoltaik.at/api/send_email.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      })
      .then((response) => response.json())
      .then((data) => {
        // if success
        if(data.success){
          this.setState({
            name:'',
            lastName:'',
            phone:'',
            email:'',
            street:'',
            houseNumber:'',
            zip:'',
            city:'',
            länge:'',
            breite:'',
            Leistung : '',
            Jahresverbrauch : '',
            Netzbetreiber : '',
            succesMsg:true,
            checkedBreiteLänge:false,
            dachRoof:'',
            brick:'',
            storage:'',
            carCharger:'',
            note:'',
            imagePath: null,
            imagePath2: null,


           })
        }
      }
      )
      .catch((error) => {
        console.error('Error:', error);
      });
      this.setState({ succesMsg: true });

      window.scrollTo(0, 0); 

  }
  componentDidUpdate(prevProps: Readonly<IChildTabProps>, prevState: Readonly<IChildTabState>, snapshot?: any): void {
    if (prevProps.activeTab !== this.props.activeTab) {
      this.setState({ activeTab: this.props.activeTab });
    }
  }

    // Function to convert file to Base64
    getBase64 = (file: File): Promise<string | null> => {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = error => {
              console.error('Error converting file to Base64:', error);
              reject(error);
          };
      });
  };


    // Handle file input changes
    handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files && files[0]) {
          try {
              const base64String = await this.getBase64(files[0]);
              this.setState({ imagePath: base64String });
          } catch (error) {
              console.error('Error processing the file', error);
          }
      }
  };


  handlePhoto2Change = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
        try {
            const base64String = await this.getBase64(files[0]);
            this.setState({ imagePath2: base64String });
        } catch (error) {
            console.error('Error processing the file', error);
        }
    }
};

  public render(): JSX.Element{
    const { activeTab } = this.props;
    return (
      <div className=" mb-6">
          {
                              this.state.succesMsg?
                              <div className="bg-green-100 border border-green-400 text-green-700 px-4 p-3 rounded relative mx-6 my-20" role="alert">
                              <strong className="font-bold">Email wurde erfolgreich versendet!</strong>
                              <p className="">Bitte prüfen Sie Ihr Postfach.</p>
                              <p className="">Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen</p>

                              <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => this.setState({ succesMsg:false })}>
                                <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                  <title>Close</title>
                                  <path d="M14.348 14.849a1 1 0 01-1.414 0L10 11.414l-2.93 2.93a1 1 0 01-1.414-1.414l2.93-2.93-2.93-2.93a1 1 0 011.414-1.414l2.93 
                                  2.93 2.93-2.93a1 1 0 011.414 1.414l-2.93 2.93 2.93 2.93a1 1 0 010 1.414z"/>
                                </svg>
                              </span>

                            </div>
                            :     
                            <div className="p-4 mt-6 items-center justify-center m-auto">
                            {activeTab === 1 && (
                                    <div>
                                      <div className="my-3">
                                        {/* Section Heading */}
                                        <p className="text-center text-2xl font-bold">Dachfläche</p>
                                        <p className="text-center text-lg font-bold">Wählen Sie die Art der Dachfläche</p>
                                        <p className="text-center text-lg font-bold">
                                          Bitte wählen Sie den Dachtyp aus, den Sie mit der Solaranlage ausstatten möchten.
                                        </p>
                                      </div>
                                      <div className="grid lg:grid-cols-3 gap-4">
                                        {/* Mapping through Roof Types */}
                                        {this.artDerDachflacheRoof.map((step, index: number) => (
                                          <div key={step.id} className="flex flex-col items-center justify-center">
                                            <button 
                                              onClick={() => {this.handleTabChange(2);  
                                                this.setState({dachRoof:step.name});
                                          // Check window width
                                          const scrollDistance = window.innerWidth < 536 ? 500 : 0; // Adjust 768 to your breakpoint
                                          window.scrollTo(0, scrollDistance); 
                                              }}  
                                              className="flex flex-col items-center justify-center w-[90%]"
                                            >
                                              {/* Card Image Component */}
                                              <CardImage
                                                img={step.img}
                                                title={step.name}
                                                isWithimg={true}
                                              />
                                            </button>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                    
                    
                    
                              {activeTab === 2 && (
                              <div>
                                {this.state.dachRoof === 'Flachdach' || this.state.dachRoof === 'Pultdach' ? 
                                    <div className="items-center justify-center w-[90%] m-auto shadow-lg rounded-lg p-4 my-3">
                                    <p className="text-center text-2xl font-bold">Deckenraum</p>
                                    <p className="text-center text-lg font-bold">Fügen Sie Platz an der Decke hinzu</p>
                                    <p className="text-center text-lg font-bold">Bitte geben Sie die Breite und Länge des Daches ein.</p>
                                <div>
                                  
                                  <input type="number"
                                  disabled={this.state.checkedBreiteLänge}
                                  onChange={(e) => this.setState({ länge: e.target.value })}
                                  placeholder="länge *"
                                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                                   />
                                </div>

                                <div>

                                  <input type="number"
                                  disabled={this.state.checkedBreiteLänge}
                                  onChange={(e) => this.setState({ breite: e.target.value })}
                                  placeholder="breite *"
                                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"

                                   />
                                </div>
                                <div className="relative flex flex-wrap items-center">
                                  <input
                                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-200 checked:hover:border-emerald-600 checked:hover:bg-emerald-300 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-400 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                                    type="checkbox"
                                    checked={this.state.checkedBreiteLänge}
                                    onClick={() => this.setState({ checkedBreiteLänge: !this.state.checkedBreiteLänge, länge:'',breite:''})}
                                  />
                                  <label
                                  onClick={() => this.setState({ checkedBreiteLänge: !this.state.checkedBreiteLänge })}
                                    className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                                    htmlFor="id-c04"
                                  >
                                    Ich habe keine Ahnung
                                  </label>
                                  <svg
                                    className="pointer-events-none absolute left-0 top-1 h-4 w-4 -rotate-90 fill-emerald-500 stroke-emerald-500 opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-hover:fill-emerald-600 peer-hover:stroke-emerald-600 peer-focus:fill-emerald-700 peer-focus:stroke-emerald-700 peer-disabled:cursor-not-allowed"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    aria-labelledby="title-01 description-01"
                                    role="graphics-symbol"
                                  >
                                    <title id="title-01">Check mark icon</title>
                                    <desc id="description-01">
                                      Check mark icon to indicate whether the radio input is checked or
                                      not.
                                    </desc>
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                                    />
                                  </svg>
                              </div>
                                <div className="flex lg:flex-1 w-full cursor-pointer my-6 mx-auto lg:gap-6 flex-col">
                                 <button
                                 disabled={
                                  !this.state.checkedBreiteLänge &&
                                  (this.state.länge === '' || this.state.breite === '')
                                }
                                
                                          onClick={() => {this.handleTabChange(3);  
                                          // Check window width
                                          const scrollDistance = window.innerWidth < 536 ? 500 : 0; // Adjust 768 to your breakpoint
                                          window.scrollTo(0, scrollDistance); 
                                          }}  
                                        className="bg-green-500 text-white font-semibold hover:bg-green-600 py-2 px-4  hover:border-transparent rounded w-1/2 mx-auto my-3
                                        disabled:opacity-50 disabled:cursor-not-allowed
                                        ">
                                      Next
                                      </button>
                                 <button
                                          onClick={() => {this.handleTabChange(1);  
                                            this.setState({ note:'' });
                                            const scrollDistance = window.innerWidth < 536 ? 500 : 0; // Adjust 768 to your breakpoint
                                            window.scrollTo(0, scrollDistance); 
                                          }}  
                                        className="bg-red-500 text-white font-semibold hover:bg-red-600 py-2 px-4  hover:border-transparent rounded w-1/2 mx-auto my-3">
                                      Back
                                      </button>
                                 </div>
                                                </div> 
                                    : <>
                                    <div className="my-3">
                           <p className="text-center text-2xl font-bold">Eindeckung</p>
                           <p className="text-center text-lg font-bold">Wählen Sie die Eindeckung</p>
                           <p className="text-center text-lg font-bold">Bitte wählen Sie die Eindeckung aus, die Sie mit einer Solaranlage ausstatten möchten.</p>
                         </div>
                         <div className="grid lg:grid-cols-3 gap-4">
                           {this.brickRoof.map((step, index:number) => (
                             <div key={step.id} className="flex flex-col items-center justify-center">
                               <button
                                   onClick={() => {this.handleTabChange(3);  
                                     this.setState({ brick:step.name });
                                   // Check window width
                                   const scrollDistance = window.innerWidth < 536 ? 500 : 0; // Adjust 768 to your breakpoint
                                   window.scrollTo(0, scrollDistance);                       }}  
                                className="flex flex-col items-center justify-center w-[90%]">
                                 <CardImage
                                   img={step.img}
                                   title={step.name}
                                   isWithimg={true}
                                   
                                 />
                    
                               </button>
                             </div>
                           ))}
                    
                         </div>
                         <div className="flex flex-1 w-full cursor-pointer my-6 mx-auto">
                          <button
                                   onClick={() => {this.handleTabChange(1);  
                                     this.setState({ brick:'' });
                                   // Check window width
                                   const scrollDistance = window.innerWidth < 536 ? 500 : 0; // Adjust 768 to your breakpoint
                                   window.scrollTo(0, scrollDistance);                      }}  
                                 className="bg-red-500 text-white font-semibold hover:bg-red-600 py-2 px-4  hover:border-transparent rounded w-1/2 mx-auto">
                               Back
                               </button>
                          </div></>}         
                    
                              </div>
                              )}
                    
                              {activeTab === 3 && (<div>
                                <div className="my-3">
                                  <p className="text-center text-2xl font-bold">Solarenergiespeicher</p>
                                  <p className="text-center text-lg font-bold">Wählen Sie den Solarenergiespeicher</p>
                                  <p className="text-center text-lg font-bold">Bitte wählen Sie den Solarenergiespeicher aus, den Sie mit der Solaranlage ausstatten möchten.</p>
                                </div>
                                <div className="grid lg:grid-cols-3 gap-4">
                                  {this.storageArr.map((step, index:number) => (
                                    <button
                                    onClick={() => { this.setState({ storage:step.name });
                                    this.state.carCharger !== '' && this.handleTabChange(4) 
                                          // Check window width
                                          const scrollDistance = window.innerWidth < 536 ? 1500 : 300; // Adjust 768 to your breakpoint
                                          window.scrollTo(0, scrollDistance); 
                                    }}  
                                     key={step.id} className="flex flex-col items-center justify-center">
                                      <div className="flex flex-col items-center justify-center w-[90%]">
                                        <CardImage
                                          img={step.img}
                                          title={step.name}
                                          isWithimg={true}
                                          
                                        />
                    
                                      </div>
                                    </button>
                                  ))}
                                </div>
                                <div className="items-center justify-center w-[90%] m-auto shadow-lg rounded-lg p-4 my-3">
                                    <p className="text-center text-2xl font-bold">Elektroauto-Ladegerät</p>
                                    <p className="text-center text-lg font-bold">Bitte wählen Sie aus, ob Sie die Solaranlage mit einem Elektroauto-Ladegerät ausstatten möchten.</p>
                                  </div>
                                <div className="grid lg:grid-cols-3 gap-4">
                               
                    
                                  {this.carChargerArr.map((step, index:number) => (
                                    <button
                                    onClick={() => { this.setState({ carCharger:step.name });
                                    this.state.storage !== '' && this.handleTabChange(4) 
                                          // Check window width
                                          const scrollDistance = window.innerWidth < 536 ? 500 : 0; // Adjust 768 to your breakpoint
                                          window.scrollTo(0, scrollDistance); 
                                    }}  
                                    key={step.id} className="flex flex-col items-center justify-center">
                                      <div className="flex flex-col items-center justify-center w-[90%]">
                                        <CardImage
                                          img={step.img}
                                          title={step.name}
                                          isWithimg={true}
                                          
                                        />
                    
                                      </div>
                                    </button>
                                  ))}
                                </div>
                                <div className="flex flex-1 w-full cursor-pointer my-6 mx-auto">
                                 <button
                                          onClick={() => {this.handleTabChange(2);  
                                            this.setState({ storage:'',carCharger:'' });
                                          // Check window width
                                          const scrollDistance = window.innerWidth < 536 ? 500 : 0; // Adjust 768 to your breakpoint
                                          window.scrollTo(0, scrollDistance); 
                                          }}  
                                        className="bg-red-500 text-white font-semibold hover:bg-red-600 py-2 px-4  hover:border-transparent rounded w-1/2 mx-auto">
                                      Back
                                      </button>
                                 </div>
                              </div>)}
                    
                              {activeTab === 4 && (<div>
                                <div className="my-3">
                                  <p className="text-center text-2xl font-bold">Bemerkung/Zusätzliche Informationen</p>
                                  <p className="text-center text-lg font-bold">Geben Sie die Bemerkung/Zusätzliche Informationen</p>
                                </div>
                                <div>
                                  <textarea
                                  onChange={(e) => this.setState({ note: e.target.value })}
                                   placeholder="Bitte geben Sie die Bemerkung/Zusätzliche Informationen an, die Sie mit einer Solaranlage ausstatten möchten." 
                                  name="" id="" cols={30} rows={10}
                                  className="border-t-2 border-b-2 border-gray-300 p-2 w-full my-3 outline-none"></textarea>
                                </div>
                                <div className="flex lg:flex-1 w-full cursor-pointer my-6 mx-auto lg:gap-6 flex-col">
                                 <button
                                          onClick={() => {this.handleTabChange(5);  
                                          // Check window width
                                          const scrollDistance = window.innerWidth < 536 ? 500 : 0; // Adjust 768 to your breakpoint
                                          window.scrollTo(0, scrollDistance); 
                                          }}  
                                        className="bg-green-500 text-white font-semibold hover:bg-green-600 py-2 px-4  hover:border-transparent rounded w-1/2 mx-auto my-3">
                                      Next
                                      </button>
                                 <button
                                          onClick={() => {this.handleTabChange(3);  
                                            this.setState({ note:'' });
                                            const scrollDistance = window.innerWidth < 536 ? 500 : 0; // Adjust 768 to your breakpoint
                                            window.scrollTo(0, scrollDistance); 
                                          }}  
                                        className="bg-red-500 text-white font-semibold hover:bg-red-600 py-2 px-4  hover:border-transparent rounded w-1/2 mx-auto my-3">
                                      Back
                                      </button>
                                 </div>
                              </div>)}
                            {activeTab === 5 && (
                            <div className="items-center justify-center w-[90%] m-auto shadow-lg rounded-lg p-4">
                                <div className="my-3">
                                  <p className="text-center text-2xl font-bold">Adresse and Info</p>
                                  <p className="text-center text-lg font-bold">Geben Sie die Adresse and info</p>
                                  <p className="text-center text-lg font-bold">Bitte geben Sie die Adresse and info an, die Sie mit einer Solaranlage ausstatten möchten.</p>
                                </div>
                                <div>
                                  <input type="text"
                                  onChange={(e) => this.setState({ name: e.target.value })}
                                  placeholder="Name *"
                                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                                  required
                                  />
                                </div>
                                <div>
                                  <input type="text"
                                  onChange={(e) => this.setState({ lastName: e.target.value })}
                                  placeholder="Nachname *"
                                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                                  />
                                </div>
                                <div>
                                  <input type="text"
                                  onChange={(e) => this.setState({ phone: e.target.value })}
                                  placeholder="Telefonnummer *"
                                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                                   />
                                </div>
                                <div>
                                  <input type="mail"
                                  onChange={(e) => this.setState({ email: e.target.value })}
                                  placeholder="Email *"
                                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                                  />
                                </div>
                                <div>
                                  <input type="text"
                                  onChange={(e) => this.setState({ street: e.target.value })}
                                  placeholder="Straße *"
                                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                                  />
                                </div>
                                <div>
                                  <input type="text"
                                  onChange={(e) => this.setState({ houseNumber: e.target.value })}
                                  placeholder="Hausnummer *"
                                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                                  />
                                </div>
                                <div>
                                  <input type="text"
                                  onChange={(e) => this.setState({ zip: e.target.value })}
                                  placeholder="PLZ *"
                                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                                  />
                                </div>
                                <div>
                                  <input type="text"
                                  onChange={(e) => this.setState({ city: e.target.value })}
                                  placeholder="Ort *"
                                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                                   />
                                </div>
                                <div>
                                  <input type="text"
                                  onChange={(e) => this.setState({ conditionFuseBox: e.target.value })}
                                  placeholder="Zustandssicherungskasten *"
                                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                                   />
                                </div>
                                <div>
                                  <input type="text"
                                  onChange={(e) => this.setState({ Leistung: e.target.value })}
                                  placeholder="Leistung"
                                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                                />
                                </div>
                                <div>
                                  <input type="text"
                                  onChange={(e) => this.setState({ Jahresverbrauch: e.target.value })}
                                  placeholder="Jahresverbrauch"
                                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                                />
                                </div>
                                <div>
                                  <input type="text"
                                  onChange={(e) => this.setState({ Netzbetreiber: e.target.value })}
                                  placeholder="Netzbetreiber"
                                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                                />
                                </div>  
                                <div className="max-w-xs mx-auto">
                                <div className="mb-4">
                                  <label htmlFor="photo1" className="block text-gray-700 font-bold mb-2">Sicherungskasten Bild * :</label>
                                  <input
                                    type="file"
                                    id="photo1"
                                    onChange={this.handleFileChange }
                                    accept="image/*"
                                    className="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  />
                                </div>
                                <div className="mb-4">
                                  <label htmlFor="photo2" className="block text-gray-700 font-bold mb-2">Dachfläche Bild:</label>
                                  <input
                                    type="file"
                                    id="photo2"
                                    onChange={this.handlePhoto2Change}
                                    accept="image/*"
                                    className="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  />
                                </div>
                            </div>
                                <div className="flex lg:flex-1 w-full cursor-pointer my-6 mx-auto lg:gap-6 flex-col">
                                 <button
                                          onClick={() => {this.handleTabChange(4);  
                                            this.setState({ 
                                              name:'',
                                              lastName:'',
                                              phone:'',
                                              email:'',
                                              street:'',
                                              houseNumber:'',
                                              zip:'',
                                              city:'',
                                              länge:'',
                                              breite:'',
                                              Leistung : '',
                                              Jahresverbrauch : '',
                                              Netzbetreiber : '',
                                              conditionFuseBox:'', 
                                              imagePath: null,
                                            });
                                              
                                          // Check window width
                                          const scrollDistance = window.innerWidth < 536 ? 500 : 0; // Adjust 768 to your breakpoint
                                          window.scrollTo(0, scrollDistance); 
                                          }}  
                                          className="bg-red-500 text-white font-semibold hover:bg-red-500 py-2 px-4  hover:border-transparent rounded w-1/2 mx-auto my-3">
                    
                                      Back
                                      </button>
                                <button
                              disabled={
                                this.state.name === '' ||
                                this.state.lastName === '' ||
                                this.state.phone === '' ||
                                this.state.email === '' ||
                                this.state.street === '' ||
                                this.state.houseNumber === '' ||
                                this.state.zip === '' ||
                                this.state.city === '' ||
                                this.state.dachRoof === '' ||
                                this.state.storage === '' ||
                                this.state.carCharger === '' ||
                                this.state.conditionFuseBox === ''||
                                this.state.imagePath === null 
                              }
                              
                                          onClick={() => {
                                           this.handleSendEmail();
                                            
                    
                                          }}  
                                          className="bg-green-500 text-white font-semibold hover:bg-green-600 py-2 px-4  hover:border-transparent rounded w-1/2 mx-auto my-3
                                          disabled:opacity-50 disabled:cursor-not-allowed
                                          ">
                    
                    Senden
                                      </button>
                                 </div>
                              </div>)}
                    
                    
                    
                            </div>


                              }

      </div>
    );
  }
}
