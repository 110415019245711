import * as React from 'react';
import { NavLink } from "react-router-dom";


export interface IHeaderProps {
}

export interface IHeaderState {
    isTop: boolean;
    isNavbarTogglerActive: boolean;
    subMenuActive: boolean;
}

export default class Header extends React.Component<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props);

    this.state = {
      isTop: true,
      isNavbarTogglerActive: false,
      subMenuActive: false,


    }
   // Bind methods to the component instance
   this.handleNavbarToggle = this.handleNavbarToggle.bind(this);
   this.handleNavItemClick = this.handleNavItemClick.bind(this);
   this.handleSubmenuClick = this.handleSubmenuClick.bind(this);
   this.handleDocumentClick = this.handleDocumentClick.bind(this);

   this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    // Add scroll event listener when the component mounts
    window.addEventListener('scroll', this.handleScroll);
    // Add click event listener to close the menu when clicking outside of it
    document.addEventListener('click', this.handleDocumentClick);
  }
  
  componentWillUnmount() {
    // Remove scroll and click event listeners when the component is about to unmount
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('click', this.handleDocumentClick);
  }
  handleNavbarToggle() {
    this.setState((prevState) => ({
      isNavbarTogglerActive: !prevState.isNavbarTogglerActive,
    }));
  }

  handleNavItemClick() {
    this.setState({
      isNavbarTogglerActive: false,
    });
  }

  handleSubmenuClick(event: any) {
    event.preventDefault();
    this.setState((prevState) => ({
      subMenuActive: !prevState.subMenuActive,
    }));
  }

  handleScroll() {
    const scrollPosition = window.scrollY;
    this.setState({ isTop: scrollPosition === 0 });
  }

  handleDocumentClick(event: MouseEvent) {
    const navbar = document.getElementById('navbarCollapse');
    const navbarToggler = document.getElementById('navbarToggler');
  
    // If the click is outside the navbar and the navbar toggler is not clicked
    if (navbar && navbarToggler && !navbar.contains(event.target as Node) && !navbarToggler.contains(event.target as Node)) {
      this.setState({
        isNavbarTogglerActive: false,
        subMenuActive: false,
      });
    }
  }

  public render(): JSX.Element {
    const { isTop } = this.state;

    return (
<div>
    {/* <!-- ====== Navbar Section Start --> */}
    <div className={`ud-header ${isTop ? ' absolute ' : ' sticky '} top-0 left-0 z-40 flex w-full items-center bg-transparent wow fadeIn`} data-wow-duration="2s" data-wow-delay="0.5s">
    <div className="container">
      <div className="relative -mx-4 flex items-center justify-between">
      <NavLink to="/home">

        <div className="w-60 max-w-full px-4">
          <button className="navbar-logo block w-full py-4">
            {isTop ? (
                <img src="../../../img/logo/wight_logo.png" alt="logo" className="header-logo w-2/3 m-2 sm:w-6 md:w-7 md:m-4 lg:w-2/3" />
                ) : (
                <img src="../../../img/logo/wight_logo.png" alt="logo2" className="header-logo w-2/3 m-2 sm:w-6 md:w-7 md:m-4 lg:w-2/3" />
                )}

          </button>
        </div>
        </NavLink>
        <div className="flex w-full items-center justify-between px-4">
          <div>
            <button onClick={this.handleNavbarToggle} id="navbarToggler"
              className="absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden">
              <span className="relative my-[6px] block h-[2px] w-[30px] bg-black"></span>
              <span className="relative my-[6px] block h-[2px] w-[30px] bg-black"></span>
              <span className="relative my-[6px] block h-[2px] w-[30px] bg-black"></span>
            </button>
            <nav id="navbarCollapse"
              className={`absolute right-4 top-full ${this.state.isNavbarTogglerActive?"":"hidden"} w-full max-w-[250px] rounded-lg bg-white py-5 shadow-lg lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:py-0 lg:px-4 lg:shadow-none xl:px-6`}>
              <ul className="blcok lg:flex 2xl:ml-20">
                <li className="group relative">
                <NavLink to="/home">

                  <button
                  onClick={this.handleNavItemClick} 
                    className="ud-menu-scroll mx-8 flex py-2 font-medium text-base text-dark group-hover:text-primary lg:mr-0 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70">
                    Home
                    </button>
                </NavLink>
                </li>
                <li className="group relative">
                <NavLink to="/aboutus">

                  <button
                  onClick={this.handleNavItemClick}
                    className="ud-menu-scroll mx-8 flex py-2 font-medium text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70 xl:ml-10">
                    Über uns
                  </button>
                  </NavLink>
                </li>
                <li className="group relative">
                <NavLink to="/ourprojects">
                  <button
                  onClick={this.handleNavItemClick}
                    className="ud-menu-scroll mx-8 flex py-2 font-medium text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70 xl:ml-10">
                    Unsere Projekte
                  </button>
                  </NavLink>
                </li>
                <li className="group relative">
                <NavLink to="/services">
                  <button
                  onClick={this.handleNavItemClick}
                    className="ud-menu-scroll mx-8 flex py-2 font-medium text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70 xl:ml-10">
                    Services
                  </button>
                  </NavLink>
                </li>
                <li className="group relative">
                <NavLink to="/contact">
                  <button
                  onClick={this.handleNavItemClick}
                    className="ud-menu-scroll mx-8 flex py-2 font-medium text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70 xl:ml-10">
                    Kontakt
                  </button>
                  </NavLink>
                </li>

              </ul>
              <section className='block lg:hidden xl:hidden'>
        <div className=" px-6 m-auto text-black text-sm bg-gray-100 p-2 ">
          <div className="grid grid-cols-4 gap-1 md:grid-cols-8 lg:grid-cols-12 ">
            <div className="col-span-4"><p>Inhaber: Rewan Nanno</p></div>
            <div className="col-span-4"><p>Geschäftsführer: Fadi Alqazzazee</p></div>
            <div className="col-span-4"><p>TEL: 06769212103</p></div>
          </div>
        </div>
      </section>
            </nav>
          </div>
         <div className="hidden justify-end pr-16 sm:flex lg:pr-0">
              {/**
               *             <a href="signin.html" className="loginBtn py-2 px-[22px] text-base font-medium text-BLACK hover:opacity-70">
                            Sign In
                          </a>
              */}
            <NavLink to="/services">
            <button
            onClick={this.handleNavItemClick} 
            className="signUpBtn rounded-md bg-blue-600 bg-opacity-100 py-2 px-6 text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-60 hover:text-white">
                          Kostenloses Angebot

              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  </div>
{ /* <!-- ====== Navbar Section End --> */}


</div>
    );
  }
}