import * as React from 'react';
import Banner from '../Section/Banner';
import { NavLink } from 'react-router-dom';
import CarouselTestimonial from '../Section/CarouselTestimonial';

export interface IAboutUsProps {
}

export interface IAboutUsState {
    currentIndex: number;
    carouselInterval?: NodeJS.Timeout | null; // Declare carouselInterval as a property

}

export default class AboutUs extends React.Component<IAboutUsProps, IAboutUsState> {
  constructor(props: IAboutUsProps) {
    super(props);
    this.state = {
        currentIndex: 0,
      };
    }
  
    componentDidMount() {
      this.startCarousel();
    }
  
    componentWillUnmount() {
      this.clearCarouselInterval();
    }
  
    startCarousel() {
      const carouselInterval = setInterval(() => {
        this.setState((prevState) => ({
          currentIndex: (prevState.currentIndex + 1) % this.logoImages.length,
        }));
      }, 4500);
  
      this.setState({ carouselInterval });
    }
  
    clearCarouselInterval() {
      const { carouselInterval } = this.state;
      if (carouselInterval) {
        clearInterval(carouselInterval);
      }
    }
  
    logoImages = [
      "https://Tailwindmix.b-cdn.net/carousel/logos/carousel-logo-image-1.svg",
      "https://Tailwindmix.b-cdn.net/carousel/logos/carousel-logo-image-2.svg",
      "https://Tailwindmix.b-cdn.net/carousel/logos/carousel-logo-image-3.svg",
      "https://Tailwindmix.b-cdn.net/carousel/logos/carousel-logo-image-4.svg",
      "https://Tailwindmix.b-cdn.net/carousel/logos/carousel-logo-image-5.svg",
      "https://Tailwindmix.b-cdn.net/carousel/logos/carousel-logo-image-6.svg",
    ];

  public render() {
    const { currentIndex } = this.state;

    return (
      <div>
        <Banner isHome={false} pageName="Über uns" color="white"/>
       {/* ====== About Section Start */}
  <section id="about" className="bg-gray-1 pt-20 pb-8 lg:pt-[120px] lg:pb-[70px]">
    <div className="container">
      <div className="wow fadeInUp" data-wow-delay=".2s">
        <div className="-mx-4 flex flex-wrap items-center">
          <div className="w-full px-4 lg:w-1/2">
            <div className="mb-12 max-w-[540px] lg:mb-0">
              <h2 className="mb-5 text-3xl font-bold leading-tight text-dark sm:text-[40px] sm:leading-[1.2]">
              Willkommen bei RNZ-Photovoltaik
              </h2>
              <p className="mb-10 text-base leading-relaxed text-body-color">
              Ihrem vertrauenswürdigen Partner für nachhaltige Energielösungen in Wien und ganz Österreich. Mit einer klaren Vision und einem engagierten Team sind wir führend in der Planung, Installation und Wartung von Photovoltaik-Anlagen. Unsere Leidenschaft für saubere Energie treibt uns an, innovative Lösungen zu entwickeln und unseren Kunden einen echten Mehrwert zu bieten.              </p>

              <a href="#"
                className="inline-flex items-center justify-center py-3 text-base font-medium text-center text-white border border-primary rounded-md px-7 bg-primary hover:bg-blue-dark hover:border-blue-dark">
                Know More
              </a>
            </div>
          </div>

          <div className="w-full px-4 lg:w-1/2">
            <div className="flex flex-wrap -mx-2 sm:-mx-4 lg:-mx-2 xl:-mx-4">
              <div className="w-full px-2 sm:w-1/2 sm:px-4 lg:px-2 xl:px-4">
                <div className="mb-4 sm:mb-8 sm:h-[400px] md:h-[540px] lg:h-[400px] xl:h-[500px]">
                  <img src="../../../img/aboutus/saveWorld.jpg" alt="about image"
                    className="object-cover object-scale-down w-full h-full" />
                </div>
              </div>

              <div className="w-full px-2 sm:w-1/2 sm:px-4 lg:px-2 xl:px-4">
                <div className="mb-4 sm:mb-8 sm:h-[220px] md:h-[346px] lg:mb-4 lg:h-[225px] xl:mb-8 xl:h-[310px]">
                  <img src="../../../img/aboutus/about-image-022.jpg" alt="about image"
                    className="object-cover object-scale-down w-full h-full" />
                </div>

                <div
                  className="bg-gray-400 relative z-10 mb-4 flex items-center justify-center overflow-hidden py-12 px-6 sm:mb-8 sm:h-[160px] sm:p-5 lg:mb-4 xl:mb-8">
                  <div>
                    <span className="block text-5xl font-extrabold text-black">
                      5
                    </span>
                    <span className="block text-base font-semibold text-white">
                    Wir haben
                    </span>
                    <span className="block text-base font-medium text-white text-opacity-70">
                    Jahre Erfahrung
                    </span>
                  </div>
                  <div>
                    <span className="absolute top-0 left-0 -z-10">
                      <svg width="106" height="144" viewBox="0 0 106 144" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.1" x="-67" y="47.127" width="113.378" height="131.304"
                          transform="rotate(-42.8643 -67 47.127)" fill="url(#paint0_linear_1416_214)" />
                        <defs>
                          <linearGradient id="paint0_linear_1416_214" x1="-10.3111" y1="47.127" x2="-10.3111"
                            y2="178.431" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="white" stop-opacity="0" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    <span className="absolute top-0 right-0 -z-10">
                      <svg width="130" height="97" viewBox="0 0 130 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.1" x="0.86792" y="-6.67725" width="155.563" height="140.614"
                          transform="rotate(-42.8643 0.86792 -6.67725)" fill="url(#paint0_linear_1416_215)" />
                        <defs>
                          <linearGradient id="paint0_linear_1416_215" x1="78.6495" y1="-6.67725" x2="78.6495"
                            y2="133.937" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="white" stop-opacity="0" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    <span className="absolute bottom-0 right-0 -z-10">
                      <svg width="175" height="104" viewBox="0 0 175 104" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.1" x="175.011" y="108.611" width="101.246" height="148.179"
                          transform="rotate(137.136 175.011 108.611)" fill="url(#paint0_linear_1416_216)" />
                        <defs>
                          <linearGradient id="paint0_linear_1416_216" x1="225.634" y1="108.611" x2="225.634" y2="256.79"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="white" stop-opacity="0" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </section>
    {/* ====== About Section End */}
   {/* ====== CTA Section Start */}
  <section className="relative z-10 overflow-hidden bg-blue-900 py-20 lg:py-[115px]">
    <div className="container mx-auto">
      <div className="relative overflow-hidden">
        <div className="flex flex-wrap items-stretch -mx-4">
          <div className="w-full px-4">
            <div className="mx-auto max-w-[570px] text-center">
              <h2 className="mb-2.5 text-lg font-bold md:leading-[1.44] text-white md:text-[28px]">
                <span>Kundenzufriedenheit ist unsere Priorität!</span>
              </h2>
              <p className="mb-6 mx-auto max-w-[515px] text-base leading-[1.5] text-white">
              Ihre Zufriedenheit steht bei uns an erster Stelle. Wir legen großen Wert auf transparente Kommunikation, kundenorientierte Prozesse und maßgeschneiderte Lösungen. Durch kontinuierliche Zusammenarbeit streben wir danach, Ihre Erwartungen zu übertreffen und langfristige Partnerschaften aufzubauen.              </p>
     
              <NavLink to="/services">
              <button className="inline-block py-3 text-base font-medium text-white transition border border-transparent rounded-md bg-secondary px-7 hover:bg-[#0BB489]">
              Kostenloses Angebot
                {/** svg right Arrow */}
              </button>
            </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <span className="absolute top-0 left-0">
        <svg width="495" height="470" viewBox="0 0 495 470" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="55" cy="442" r="138" stroke="white" stroke-opacity="0.04" strokeWidth="50" />
          <circle cx="446" r="39" stroke="white" stroke-opacity="0.04" strokeWidth="20" />
          <path d="M245.406 137.609L233.985 94.9852L276.609 106.406L245.406 137.609Z" stroke="white"
            stroke-opacity="0.08" strokeWidth="12" />
        </svg>
      </span>
      <span className="absolute bottom-0 right-0">
        <svg width="493" height="470" viewBox="0 0 493 470" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="462" cy="5" r="138" stroke="white" stroke-opacity="0.04" strokeWidth="50" />
          <circle cx="49" cy="470" r="39" stroke="white" stroke-opacity="0.04" strokeWidth="20" />
          <path d="M222.393 226.701L272.808 213.192L259.299 263.607L222.393 226.701Z" stroke="white"
            stroke-opacity="0.06" strokeWidth="13" />
        </svg>
      </span>
    </div>
  </section>
  {/* ====== CTA Section End */}
  <section id="about" className="bg-[#E6E6FA] pt-20 pb-8  lg:pb-[70px]">
    <div className="container">
        <h1 className='text-[#662d91] text-center lg:text-2xl text-lg font-bold pb-3'>
        Herzlich willkommen bei RNZ-Photovoltaik – Wo Qualität und Kundenservice an erster Stelle stehen.
        </h1>
      <div className="wow fadeInUp" data-wow-delay=".2s">
        <div className="-mx-4 flex flex-wrap items-center">
          <div className="w-full px-4 lg:w-1/2">
               {/*<!-- Component: Basic card --> */}
      <div className="overflow-hidden rounded-lg text-white shadow-lg shadow-slate-100 lg:h-80 lg:pt-10 my-3 bg-[#720e9e]">
        <div className="p-6">
        <h3 className="mb-4 text-xl font-medium text-white text-center">
        Erfahrung
        </h3>
          <p>
          Unsere langjährige Erfahrung im Bereich der Solarenergie macht uns zu Experten in der Installation und Optimierung von Solaranlagen. Mit einer beeindruckenden Erfolgsgeschichte haben wir zahlreichen Kunden geholfen, ihre Energieeffizienz zu steigern und gleichzeitig ihren ökologischen Fußabdruck zu reduzieren. Vertrauen Sie auf unsere bewährte Expertise, um die Sonnenenergie optimal für Ihr Zuhause oder Ihr Unternehmen zu nutzen.          </p>
        </div>
      </div>
      {/*<!-- End Basic card --> */}
          </div>

          <div className="w-full px-4 lg:w-1/2">
                {/*<!-- Component: Basic card --> */}
      <div className="overflow-hidden  text-white shadow-lg shadow-slate-100 lg:h-80 lg:pt-10 rounded-lg my-3 bg-[#4B0082]">
        <div className="p-6">
          <h3 className="mb-4 text-xl font-medium text-white text-center">
          Kundenzufriedenheit im Fokus
        </h3>
          <p>
          Bei uns steht die Zufriedenheit unserer Kunden an erster Stelle. Wir legen großen Wert darauf, nicht nur erstklassige Solarlösungen zu bieten, sondern auch einen herausragenden Kundenservice. Von der ersten Beratung bis zur erfolgreichen Installation sind wir stets bestrebt, Ihre individuellen Bedürfnisse zu verstehen und zu erfüllen. Ihre Zufriedenheit ist unser Antrieb, und wir sind erst dann zufrieden, wenn Sie es sind.          </p>
        </div>
      </div>
      {/*<!-- End Basic card --> */}
            </div>
        </div>
        </div>
      <div className="wow fadeInUp" data-wow-delay=".2s">
        <div className="-mx-4 flex flex-wrap items-center">
          <div className="w-full px-4 lg:w-1/2">
               {/*<!-- Component: Basic card --> */}
      <div className="overflow-hidden rounded-lg text-white shadow-lg shadow-slate-100 lg:h-80 lg:pt-10 my-3 bg-[#DDA0DD]">
        <div className="p-6">
        <h3 className="mb-4 text-xl font-medium text-white text-center">
        Vision
        </h3>
          <p>
          Unsere Vision ist es, eine nachhaltige Zukunft durch die Förderung erneuerbarer Energien zu gestalten. Wir glauben an die transformative Kraft der Solarenergie und setzen uns leidenschaftlich dafür ein, sie für jeden zugänglich zu machen. Mit Innovation, Zuverlässigkeit und Umweltbewusstsein streben wir danach, eine Welt zu schaffen, in der saubere Energie die Norm ist und Gemeinschaften florieren.          </p>
        </div>
      </div>
      {/*<!-- End Basic card --> */}
          </div>

          <div className="w-full px-4 lg:w-1/2">
                {/*<!-- Component: Basic card --> */}
      <div className="overflow-hidden  text-white shadow-lg shadow-slate-100 lg:h-80 lg:pt-10 rounded-lg my-3 bg-[#800080]">
        <div className="p-6">
          <h3 className="mb-4 text-xl font-medium text-white text-center">
          Das Ziel
        </h3>
          <p>
          Unser Ziel ist es, Ihren Weg zu sauberer Energie so reibungslos wie möglich zu gestalten. Wir möchten nicht nur Solaranlagen installieren, sondern Sie auf eine Reise mitnehmen, die Ihre Energieeffizienz steigert und gleichzeitig die Umwelt schützt. Durch unser engagiertes Team und modernste Technologien arbeiten wir hart daran, Ihre Erwartungen zu übertreffen und eine nachhaltigere Zukunft für kommende Generationen zu schaffen.          </p>
        </div>
      </div>
      {/*<!-- End Basic card --> */}
            </div>
        </div>
        </div>
    </div>
    </section>
    
        
           {/*<!-- Component: Testimonial carousel --> */}
           <div className="py-20 bg-gray-1">
            <div className="container">
                <div className="wow fadeInUp" data-wow-delay=".2s">
                <div className="mb-12 text-center">
                    <h2 className="mb-4 text-3xl font-bold leading-tight text-dark sm:text-[40px] sm:leading-[1.2]">
                    <span className="text-primary">Unsere</span> Kunden
                    </h2>
                    <p className="text-base leading-relaxed text-body-color">
                    Wir sind stolz darauf, dass wir seit über 10 Jahren eine große Anzahl an zufriedenen Kunden haben. Wir freuen uns, dass wir Ihnen helfen können, Ihre Apple-Geräte wieder zum Laufen zu bringen.
                    </p>
                </div>
                <CarouselTestimonial/>

                </div>
            </div>
         
            </div>
        
        {/*<!-- End Testimonial carousel --> */}
        

      </div>
    );
    }
}

    
    

