import * as React from 'react';
import Banner from '../Section/Banner';

export interface IOurprojectsProps {
}

export interface IOurprojectsState {
}

export default class Ourprojects extends React.Component<IOurprojectsProps, IOurprojectsState> {
  constructor(props: IOurprojectsProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className='mb-10'>
                <Banner isHome={false} pageName="Unsere Projekte" color="white"/>

                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (23).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (14).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (25).jpg" alt=""/>
        </div>
    </div>
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (26).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (27).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (28).jpg" alt=""/>
        </div>
    </div>
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (29).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (30).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (31).jpg" alt=""/>
        </div>
    </div>
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (32).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (33).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (34).jpg" alt=""/>
        </div>
    </div>
</div>
<div className="grid grid-cols-2 md:grid-cols-4 gap-4">
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (4).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (2).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (3).jpg" alt=""/>
        </div>
    </div>
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (1).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (12).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (5).jpg" alt=""/>
        </div>
    </div>
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (9).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (6).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (10).jpg" alt=""/>
        </div>
    </div>
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (8).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (11).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (7).jpg" alt=""/>
        </div>
    </div>
</div>
<div className="grid grid-cols-2 md:grid-cols-4 gap-4">
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (13).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (14).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (15).jpg" alt=""/>
        </div>
    </div>
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (16).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (17).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (18).jpg" alt=""/>
        </div>
    </div>
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (19).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (20).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (21).jpg" alt=""/>
        </div>
    </div>
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (22).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (23).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (24).jpg" alt=""/>
        </div>
    </div>
</div>

<div className="grid grid-cols-2 md:grid-cols-4 gap-4">
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (35).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (36).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (37).jpg" alt=""/>
        </div>
    </div>
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (38).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (39).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (40).jpg" alt=""/>
        </div>
    </div>
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src=" ../../../img/projects/Project (41).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (42).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (43).jpg" alt=""/>
        </div>
    </div>
    <div className="grid gap-4">
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (44).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (45).jpg" alt=""/>
        </div>
        <div>
            <img className="h-auto max-w-full rounded-lg" src="../../../img/projects/Project (46).jpg" alt=""/>
        </div>
    </div>
</div>

      </div>
    );
  }
}
