// Store.tsx
import { EventEmitter } from 'events';

class Store {
  private emitter: EventEmitter;
  private activeTab: number;

  constructor() {
    this.emitter = new EventEmitter();
    this.activeTab = 1; // Default active tab
  }

  getTabActive(): number {
    return this.activeTab;
  }

  setTabActive(tab: number): void {
    this.activeTab = tab;
    this.emitter.emit('change');
  }

  subscribe(callback: () => void): void {
    this.emitter.on('change', callback);
  }

  unsubscribe(callback: () => void): void {
    this.emitter.removeListener('change', callback);
  }
}

export default new Store();
