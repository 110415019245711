import * as React from "react";
import Home from "../Pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "../Pages/Contact";
import Services from "../Pages/Services";
import AboutUs from "../Pages/AboutUs";
import ScrollToTop from "../Section/ScrollToTop";
import Ourprojects from "../Pages/Ourprojects";
import Impressum from "../Pages/Impressum";

export interface IMainProps {
}

export interface IMainState {
}

export default class Main extends React.Component<IMainProps, IMainState> {
  constructor(props: IMainProps) {
    super(props);

    this.state = {
    };
  }


  componentDidUpdate(prevProps: Readonly<IMainProps>, prevState: Readonly<IMainState>, snapshot?: any): void {

  }

  public render() {
    return (
       <div
        className="h-full lg:shadow-2xl bg-white rounded-2xl">
            <ScrollToTop />
            <Routes >
            {/*            //open home page on first load */}
            <Route path="/home" element={<Home />}  />
            <Route path="/" element={<Home />}  />
            <Route path="/ourprojects" element={<Ourprojects />}  />
            <Route path="/services" element={<Services />}  />
            <Route path="/aboutus" element={<AboutUs />}  />
            <Route path="/Contact" element={<Contact />}  />
            <Route path="/impressum" element={<Impressum />}  />

 
          </Routes> 
        </div>
    );
  }
}
