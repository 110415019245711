import React from "react";

type ServiceCardProps = {
  title: string;
  description: string;
  imageSrc: string;
  company: string;
};

class ServiceCard extends React.Component<ServiceCardProps> {
  
  render() {
    return (
      <section>
  <div className="container px-6 m-auto">
    <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
      <div className="col-span-4 lg:col-span-6 ">
      <div className="max-w-2xl rounded overflow-hidden shadow-2xl bg-white m-4 ">
        <img className="w-full" src={this.props.imageSrc} alt={this.props.title} />
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2 text-center">{this.props.title}</div>
          <p className="text-gray-700 text-base">
            {this.props.description}
          </p>
        </div>
        <div className="px-6 pt-4 pb-2">
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {this.props.company}
          </span>
        </div>
      </div>
      </div>
      <div className="col-span-4 lg:col-span-6">
<ul className="divide-y divide-slate-100">
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Dashboard icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Kosteneinsparungen: Mit Sonnenkollektoren können Sie Ihre Energiekosten senken, da Sie weniger Strom aus dem Netz beziehen müssen, was sich langfristig finanziell auszahlt.
            </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="User icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Reduzierung der CO2-Emissionen: Die Nutzung von Solarenergie hilft dabei, den Ausstoß von Treibhausgasen zu reduzieren, was einen positiven Beitrag zum Kampf gegen den Klimawandel leistet.
              </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Analytics icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Geringe Wartungskosten: Sonnenkollektoren erfordern nur minimale Wartung und haben eine lange Lebensdauer, was bedeutet, dass Sie langfristig von niedrigen Betriebskosten profitieren.
              
            </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Cloud icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Energieunabhängigkeit: Sonnenkollektoren bieten eine zuverlässige Energiequelle, die auch bei Stromausfällen funktioniert, was Ihnen eine gewisse Unabhängigkeit in Bezug auf die Energieversorgung gibt.
            </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Dashboard icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">Erneuerbare Energiequelle: Sonnenkollektoren nutzen die unerschöpfliche Energie der Sonne, was bedeutet, dass sie eine nachhaltige und umweltfreundliche Energiequelle sind.</p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="User icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Wertsteigerung der Immobilie: Die Installation von Sonnenkollektoren kann den Wert Ihrer Immobilie erhöhen, da sie als nachhaltige und energieeffiziente Lösung angesehen werden.
            </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Analytics icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Flexibilität: Sonnenkollektoren können an verschiedenen Orten installiert werden und sind in verschiedenen Größen erhältlich, was bedeutet, dass sie für eine Vielzahl von Anwendungen geeignet sind.
            </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Cloud icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Fördermöglichkeiten: Es gibt verschiedene Förderprogramme und Finanzierungsmöglichkeiten, die den Kauf und die Installation von Sonnenkollektoren unterstützen.
            </p>
        </div>
    </li>
</ul>
      </div>
    </div>
  </div>
</section>
   
    );
  }
}

type ServicesSectionProps = {
  services: ServiceCardProps[];
};

type ServicesSectionState = {
  currentIndex: number;
};

class ServicesSection extends React.Component<ServicesSectionProps, ServicesSectionState> {
  intervalId?: number;
  state = {
    currentIndex: 0,
  };

  componentDidMount() {
    this.startSlideshow();
  }

  componentWillUnmount() {
    this.stopSlideshow();
  }

  startSlideshow = () => {
    this.intervalId = window.setInterval(() => {
      this.setState(prevState => ({
        currentIndex: (prevState.currentIndex + 1) % this.props.services.length,
      }));
    }, 3000); // Slide every 3 seconds
  };

  stopSlideshow = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  };

  render() {
    const { currentIndex } = this.state;
    const { services } = this.props;
    const service = services[currentIndex];

    return (
      <div className="flex flex-wrap justify-center items-center bg-white">
        <ServiceCard
          key={service.title}
          title={service.title}
          description={service.description}
          imageSrc={service.imageSrc}
          company={service.company}
        />
      </div>
    );
  }
}

export default ServicesSection;




